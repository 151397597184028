"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", [_c("span", {
    staticClass: "forgot-password"
  }, [_vm._v("Nhập OTP")]), _c("div", {
    staticClass: "otp-container"
  }, [_c("v-otp-input", {
    ref: "otpInput",
    attrs: {
      "input-classes": "otp-input",
      separator: "-",
      "num-inputs": 6,
      "should-auto-focus": true,
      "is-input-num": true
    },
    on: {
      "on-complete": _vm.handleOnComplete
    }
  })], 1), _c("el-button", {
    staticClass: "btn-reset",
    attrs: {
      type: "primary",
      size: "",
      loading: _vm.loading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("Tiếp tục")]), _c("div", {
    staticClass: "text-center",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("a", {
    on: {
      click: function click($event) {
        return _vm.$emit("back-forgot");
      }
    }
  }, [_vm._v("Quay lại")])])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;