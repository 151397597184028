"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerChart = customerChart;
exports.dashboardApi = void 0;
exports.getDashboardCustomer = getDashboardCustomer;
exports.getDashboardDriver = getDashboardDriver;
exports.getDashboardOrder = getDashboardOrder;
exports.getDashboardTransport = getDashboardTransport;
exports.orderBookingChart = orderBookingChart;
exports.orderShoppingChart = orderShoppingChart;
exports.orderTransportChart = orderTransportChart;
exports.today = void 0;
exports.top5Customer = top5Customer;
exports.top5Driver = top5Driver;

var _request = _interopRequireDefault(require("@/utils/request"));

var dashboardApi = {
  summary: function summary() {
    return (0, _request.default)({
      url: '/v1/admin/dashboard/summary'
    });
  },
  summaryAccessHistory: function summaryAccessHistory(params) {
    return (0, _request.default)({
      url: '/v1/admin/dashboard/summary/access-history',
      params: params
    });
  },
  viewBookHistory: function viewBookHistory(params) {
    return (0, _request.default)({
      url: '/v1/admin/dashboard/view-book-history',
      params: params
    });
  },
  topBookView: function topBookView() {
    return (0, _request.default)({
      url: '/v1/admin/dashboard/top/book-view'
    });
  },
  summaryBookCategory: function summaryBookCategory() {
    return (0, _request.default)({
      url: '/v1/admin/dashboard/summary/book-category'
    });
  },
  importBookHistory: function importBookHistory(params) {
    return (0, _request.default)({
      url: '/v1/admin/dashboard/import-book',
      params: params
    });
  },
  topWinner: function topWinner() {
    return (0, _request.default)({
      url: '/dashboard/topWinner'
    });
  },
  topAds: function topAds() {
    return (0, _request.default)({
      url: '/dashboard/topAds'
    });
  },
  reportVote: function reportVote(params) {
    return (0, _request.default)({
      url: '/dashboard/report/vote',
      params: params
    });
  },
  reportCustomer: function reportCustomer(params) {
    return (0, _request.default)({
      url: '/dashboard/report/customer',
      params: params
    });
  }
};
exports.dashboardApi = dashboardApi;

function getDashboardOrder(params) {
  return (0, _request.default)({
    url: "/dashboard/order",
    method: "get",
    params: params
  });
}

var today = function today() {
  return (0, _request.default)({
    url: "/dashboard/today"
  });
};

exports.today = today;

function getDashboardTransport(params) {
  return (0, _request.default)({
    url: "/dashboard/orderTransport",
    method: "get",
    params: params
  });
}

function getDashboardDriver() {
  return (0, _request.default)({
    url: "/dashboard/driver",
    method: "get"
  });
}

function getDashboardCustomer(params) {
  return (0, _request.default)({
    url: "/dashboard/customer",
    method: "get",
    params: params
  });
}

function customerChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/customer/last30",
    method: "get",
    params: listQuery
  });
}

function orderShoppingChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/order/last30",
    method: "get",
    params: listQuery
  });
}

function orderTransportChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderTransport/last30",
    method: "get",
    params: listQuery
  });
}

function orderBookingChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/bookingTransport/last30",
    method: "get",
    params: listQuery
  });
}

function top5Driver() {
  return (0, _request.default)({
    url: "/dashboard/driver/top5",
    method: "get"
  });
}

function top5Customer() {
  return (0, _request.default)({
    url: "/dashboard/customer/top5",
    method: "get"
  });
}