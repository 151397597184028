"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeviceTypeTrans = exports.DeviceType = exports.DeviceStatusTrans = exports.DeviceStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _DeviceStatusTrans, _DeviceTypeTrans;

var DeviceType;
exports.DeviceType = DeviceType;

(function (DeviceType) {
  DeviceType["Mobile"] = "Handset";
  DeviceType["Tablet"] = "Tablet";
  DeviceType["TouchScreen"] = "TOUCH_SCREEN";
  DeviceType["Kios"] = "KIOSK";
})(DeviceType || (exports.DeviceType = DeviceType = {}));

var DeviceStatus;
exports.DeviceStatus = DeviceStatus;

(function (DeviceStatus) {
  DeviceStatus["Valid"] = "VALID";
  DeviceStatus["Invalid"] = "INVALID";
  DeviceStatus["Pending"] = "PENDING"; //Chờ duyệt
})(DeviceStatus || (exports.DeviceStatus = DeviceStatus = {}));

var DeviceStatusTrans = (_DeviceStatusTrans = {}, (0, _defineProperty2.default)(_DeviceStatusTrans, DeviceStatus.Valid, 'Hợp lệ'), (0, _defineProperty2.default)(_DeviceStatusTrans, DeviceStatus.Invalid, 'Không hợp lệ'), (0, _defineProperty2.default)(_DeviceStatusTrans, DeviceStatus.Pending, 'Chờ duyệt'), _DeviceStatusTrans);
exports.DeviceStatusTrans = DeviceStatusTrans;
var DeviceTypeTrans = (_DeviceTypeTrans = {}, (0, _defineProperty2.default)(_DeviceTypeTrans, DeviceType.Tablet, 'Máy tính bảng'), (0, _defineProperty2.default)(_DeviceTypeTrans, DeviceType.TouchScreen, 'Touch Screen'), (0, _defineProperty2.default)(_DeviceTypeTrans, DeviceType.Kios, 'KIOSK'), _DeviceTypeTrans);
exports.DeviceTypeTrans = DeviceTypeTrans;