"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Quản lý chương, tập",
      visible: _vm.visible,
      width: "1200px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c("div", [_c("label", {
    staticStyle: {
      color: "#000"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Sách: ")]), _c("span", {
    staticStyle: {
      color: "#000"
    }
  }, [_vm._v(_vm._s(_vm.book.name))])]), _c("div", {
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-plus",
      size: "mini"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(" Thêm mới chương ")]), _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-sort",
      size: "mini"
    },
    on: {
      click: _vm.openSort
    }
  }, [_vm._v(" Sắp xếp chương ")])], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.bookChapters,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "STT",
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tiêu đề",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "PDF"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.pdfLink ? _c("div", [_vm.visibleMediaLink ? _c("div", [_c("el-link", {
          attrs: {
            type: "primary",
            target: "_blank"
          }
        }, [_vm._v(" " + _vm._s(row.isAllowPdfLink ? row.pdfLink : _vm.getMediaLink(row, "pdf")) + " ")]), !row.isAllowPdfLink ? [_c("br"), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            loading: row.loadingViewPdf
          },
          on: {
            click: function click($event) {
              return _vm.viewMediaLink(row, "pdf");
            }
          }
        }, [_vm._v("Xem link")])] : _vm._e()], 2) : _c("span", [_vm._v(" Không có quyền xem ")])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Audio"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.audioLink ? _c("div", [_vm.visibleMediaLink ? _c("div", [_c("el-link", {
          attrs: {
            type: "primary",
            target: "_blank"
          }
        }, [_vm._v(" " + _vm._s(row.isAllowAudioLink ? row.audioLink : _vm.getMediaLink(row, "audio")) + " ")]), !row.isAllowAudioLink ? [_c("br"), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            loading: row.loadingViewAudio
          },
          on: {
            click: function click($event) {
              return _vm.viewMediaLink(row, "audio");
            }
          }
        }, [_vm._v("Xem link")])] : _vm._e()], 2) : _c("span", [_vm._v(" Không có quyền xem ")])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Video"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.videoLink ? _c("div", [_vm.visibleMediaLink ? _c("div", [_c("el-link", {
          attrs: {
            type: "primary",
            target: "_blank"
          }
        }, [_vm._v(" " + _vm._s(row.isAllowVideoLink ? row.videoLink : _vm.getMediaLink(row, "video")) + " ")]), !row.isAllowVideoLink ? [_c("br"), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            loading: row.loadingViewVideo
          },
          on: {
            click: function click($event) {
              return _vm.viewMediaLink(row, "video");
            }
          }
        }, [_vm._v("Xem link")])] : _vm._e()], 2) : _c("span", [_vm._v(" Không có quyền xem ")])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Khảo sát"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.surveyDataType == _vm.BookChapterSurveyDataType.Url ? _c("div", [_c("span", [_vm._v(" Link: ")]), _c("el-link", {
          staticStyle: {
            color: "#1890ff"
          },
          attrs: {
            target: "_blank",
            href: row.surveyContent
          }
        }, [_vm._v(" " + _vm._s(row.surveyContent) + " ")])], 1) : _vm._e(), row.surveyDataType == _vm.BookChapterSurveyDataType.Html ? _c("div", [_c("el-link", {
          staticStyle: {
            color: "#1890ff"
          },
          on: {
            click: function click($event) {
              return _vm.viewHtml(row.surveyContent);
            }
          }
        }, [_vm._v(" Xem dạng HTML ")])], 1) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      align: "center",
      width: "115"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("div", [_c("el-button", {
          staticStyle: {
            width: "106px"
          },
          attrs: {
            icon: "el-icon-edit",
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v(" Cập nhật ")]), _c("br"), _c("el-button", {
          staticStyle: {
            width: "106px",
            "margin-top": "4px"
          },
          attrs: {
            icon: "el-icon-delete",
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        }, [_vm._v(" Xoá ")])], 1)];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchBookChapters
    }
  }), _c("UpdateBookChapterModal", {
    ref: "UpdateBookChapterModal",
    attrs: {
      bookId: _vm.book.id
    },
    on: {
      submitOk: _vm.onSubmitOk
    }
  }), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div"), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1), _c("SortBookChapterModal", {
    ref: "SortBookChapterModal",
    on: {
      submitOk: _vm.fetchBookChapters
    }
  }), _c("ViewHtmlModal", {
    ref: "ViewHtmlModal"
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;