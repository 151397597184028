"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "auth-container"
  }, [_c("div", {
    staticClass: "form-container"
  }, [_c("div", {
    staticClass: "logo"
  }, [_c("img", {
    staticStyle: {
      "border-radius": "5px"
    },
    attrs: {
      src: _vm.logo,
      width: "150",
      alt: ""
    }
  })]), _vm.status == _vm.StatusPage.LoginEmail ? _c("login-form", {
    on: {
      "chang-status-page": _vm.handleChangeStatus
    }
  }) : _vm._e()], 1)]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;