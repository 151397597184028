import { render, staticRenderFns } from "./index.vue?vue&type=template&id=676a6052&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('676a6052')) {
      api.createRecord('676a6052', component.options)
    } else {
      api.reload('676a6052', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=676a6052&", function () {
      api.rerender('676a6052', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/highlight-book-strategy/index.vue"
export default component.exports