"use strict";

var _interopRequireWildcard = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.array.filter.js");

require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/core-js/modules/es.array.iterator.js");

require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/core-js/modules/es.promise.js");

require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/core-js/modules/es.object.assign.js");

require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/core-js/modules/es.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _vi = _interopRequireDefault(require("element-ui/lib/locale/lang/vi"));

var _locale = _interopRequireDefault(require("element-ui/lib/locale"));

require("@/plugins/animate-css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App.vue"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _index2 = _interopRequireDefault(require("@/components/NoData/index.vue"));

require("@/plugins/google-maps");

require("@/plugins/element");

require("@/plugins/vue-phone-number-input");

require("@/plugins/vue-fragment");

require("@/plugins/html-to-paper");

require("./icons");

require("./permission");

var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));

var _date = require("@/utils/date");

var _format = require("@/utils/format");

require("@/plugins/input-mask");

var _index3 = _interopRequireDefault(require("@/components/Pagination/index.vue"));

var _InputMask = _interopRequireDefault(require("@/components/InputMoney/InputMask.vue"));

var _InputMoney = _interopRequireDefault(require("@/components/InputMoney/InputMoney.vue"));

var filters = _interopRequireWildcard(require("./filters"));

require("@/plugins/font-awesome-icon");

var _permission2 = require("./utils/permission");

require("@/plugins/otp-input");

var _lodash = require("lodash");

var _currency = require("./plugins/currency");

var _url = require("./plugins/url");

// a modern alternative to CSS resets
_locale.default.use(_vi.default);

_vue.default.component('no-data', _index2.default); //google map


_vue.default.prototype.$formatDateTime = _date.formatDateTime;
_vue.default.prototype.$formatDate = _date.formatDate;
_vue.default.prototype.$formatNumberVN = _format.formatNumberVN;
_vue.default.prototype.$secondToMinutes = _date.secondToMinuteString;
_vue.default.prototype.$secondToMinuteString = _date.secondToMinuteString;
_vue.default.prototype.$checkAction = _permission2.checkAction;
_vue.default.prototype.$formatPhone = _format.formatPhone;
_vue.default.prototype.$formatDateDay = _date.formatDateDay;
_vue.default.prototype.$isEmpty = _lodash.isEmpty;
_vue.default.prototype.$DocTienBangChu = _currency.DocTienBangChu;

_vue.default.component("Pagination", _index3.default);

_vue.default.component('InputMask', _InputMask.default);

_vue.default.component("InputMoney", _InputMoney.default);

_vue.default.use(_elementUi.default, {
  size: "small" // set element-ui default size

});

_vue.default.use(_vueClipboard.default); // register global utility filters


Object.keys(filters).forEach(function (key) {
  // @ts-ignore 
  _vue.default.filter(key, filters[key]);
});
_vue.default.prototype.$devMode = process.env.VUE_APP_DEV_MODE == "true";
_vue.default.prototype.$baseUrl = process.env.VUE_APP_BASE_API;
_vue.default.prototype.$baseUrlMedia = process.env.VUE_APP_BASE_URL_MEDIA;
_vue.default.prototype.$wowzaDomain = process.env.VUE_APP_WOWZA_DOMAIN;
_vue.default.prototype.$uploadDomain = process.env.VUE_APP_UPLOAD_DOMAIN;
_vue.default.prototype.$uploadPath = process.env.VUE_APP_UPLOAD_PATH;
_vue.default.prototype.$folderUploadUri = process.env.VUE_APP_UPLOAD_FOLDER_URI;
_vue.default.prototype.$url = _url.url;
_vue.default.config.productionTip = false;
new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  // i18n,
  render: function render(h) {
    return h(_App.default);
  }
});