"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

var _vue = _interopRequireDefault(require("vue"));

var _vuePhoneNumberInput = _interopRequireDefault(require("vue-phone-number-input"));

require("vue-phone-number-input/dist/vue-phone-number-input.css");

_vue.default.component('vue-phone-number-input', _vuePhoneNumberInput.default);