"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HighlightBookStrategyStatusTrans = exports.HighlightBookStrategyStatusTagColor = exports.HighlightBookStrategyStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _HighlightBookStrateg, _HighlightBookStrateg2;

var HighlightBookStrategyStatus;
exports.HighlightBookStrategyStatus = HighlightBookStrategyStatus;

(function (HighlightBookStrategyStatus) {
  HighlightBookStrategyStatus["Pending"] = "PENDING";
  HighlightBookStrategyStatus["Approve"] = "APPROVE";
  HighlightBookStrategyStatus["Reject"] = "REJECT";
})(HighlightBookStrategyStatus || (exports.HighlightBookStrategyStatus = HighlightBookStrategyStatus = {}));

var HighlightBookStrategyStatusTrans = (_HighlightBookStrateg = {}, (0, _defineProperty2.default)(_HighlightBookStrateg, HighlightBookStrategyStatus.Pending, "Chờ duyệt"), (0, _defineProperty2.default)(_HighlightBookStrateg, HighlightBookStrategyStatus.Approve, "Đã duyệt"), (0, _defineProperty2.default)(_HighlightBookStrateg, HighlightBookStrategyStatus.Reject, "Từ chối"), _HighlightBookStrateg);
exports.HighlightBookStrategyStatusTrans = HighlightBookStrategyStatusTrans;
var HighlightBookStrategyStatusTagColor = (_HighlightBookStrateg2 = {}, (0, _defineProperty2.default)(_HighlightBookStrateg2, HighlightBookStrategyStatus.Pending, ""), (0, _defineProperty2.default)(_HighlightBookStrateg2, HighlightBookStrategyStatus.Approve, "success"), (0, _defineProperty2.default)(_HighlightBookStrateg2, HighlightBookStrategyStatus.Reject, "danger"), _HighlightBookStrateg2);
exports.HighlightBookStrategyStatusTagColor = HighlightBookStrategyStatusTagColor;