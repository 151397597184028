"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notificationApi = void 0;

var _request = require("@/utils/request");

var notificationApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/notification',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/notification',
      data: data,
      method: 'post'
    });
  },
  send: function send(id) {
    return (0, _request.request)({
      url: "/v1/admin/notification/".concat(id, "/send"),
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/notification/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/notification/".concat(id),
      method: 'delete'
    });
  }
};
exports.notificationApi = notificationApi;