"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StaffTypeTrans = exports.StaffType = exports.CreateFrom = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _StaffTypeTrans;

var StaffType;
exports.StaffType = StaffType;

(function (StaffType) {
  StaffType["Customer"] = "CUSTOMER";
  StaffType["Teacher"] = "TEACHER";
  StaffType["Staff"] = "STAFF";
  StaffType["Librarian"] = "LIBRARIAN";
  StaffType["Technician"] = "TECHNICIAN";
  StaffType["TeacherAv"] = "TEACHER_AV"; //Giảng viên cấp cao
})(StaffType || (exports.StaffType = StaffType = {}));

var StaffTypeTrans = (_StaffTypeTrans = {}, (0, _defineProperty2.default)(_StaffTypeTrans, StaffType.Customer, 'Học viên'), (0, _defineProperty2.default)(_StaffTypeTrans, StaffType.Teacher, 'Giảng viên'), (0, _defineProperty2.default)(_StaffTypeTrans, StaffType.Staff, 'Quản trị viên'), (0, _defineProperty2.default)(_StaffTypeTrans, StaffType.Librarian, 'Thủ thư'), (0, _defineProperty2.default)(_StaffTypeTrans, StaffType.Technician, 'Kỹ thuật viên'), (0, _defineProperty2.default)(_StaffTypeTrans, StaffType.TeacherAv, 'Giảng viên cấp cao'), _StaffTypeTrans);
exports.StaffTypeTrans = StaffTypeTrans;
var CreateFrom;
exports.CreateFrom = CreateFrom;

(function (CreateFrom) {
  CreateFrom["Self"] = "SELF";
  CreateFrom["Other"] = "OTHER"; //Tạo ra từ bên thứ 3
})(CreateFrom || (exports.CreateFrom = CreateFrom = {}));