"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }), _c("section", [_c("h2", [_vm._v("Chung")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Cấu hình",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", [_vm._v(_vm._s(_vm.ConfigurationTypeTrans[row.type]))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá trị"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.dataType != "boolean" ? _c("span", [_vm._v(_vm._s(row.value))]) : _c("el-switch", {
          attrs: {
            value: row.value == "true",
            "active-color": "#13ce66",
            "inactive-color": "#ff4949"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v("Cập nhật")])];
      }
    }])
  })], 1)], 1), _c("section", [_c("h2", [_vm._v("Web kiosk")]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.faceDetectionConfigurations,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Cấu hình",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("span", [_vm._v(_vm._s(_vm.ConfigurationTypeTrans[row.type]))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá trị"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("span", [_vm._v(_vm._s(row.value))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v("Cập nhật")])];
      }
    }])
  })], 1), _c("small", [_vm._v(" Nếu vẫn nhận diện được " + _vm._s(_vm.faceDetectionTime) + " lần trong " + _vm._s(_vm.faceDetectionMinute) + " phút thì hết session sẽ logout người dùng; nếu không nhận diện được trong " + _vm._s(_vm.faceDetectionTimeOut) + " lần liên tiếp thì sẽ logout ")])], 1), _c("ConfigurationModal", {
    ref: "ConfigurationModal",
    on: {
      submitOk: function submitOk() {
        _vm.fetchData();

        _vm.fetchFaceDetectionConfiguration();
      }
    }
  })], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;