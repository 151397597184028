"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "tinymce-container",
    class: {
      fullscreen: _vm.fullscreen
    },
    style: {
      width: _vm.containerWidth
    }
  }, [_c("textarea", {
    staticClass: "tinymce-textarea",
    attrs: {
      id: _vm.tinymceId
    }
  }), _vm._v(" "), _vm.visibleUpload ? _c("div", {
    staticClass: "editor-custom-btn-container"
  }, [_c("editorImage", {
    staticClass: "editor-upload-btn",
    attrs: {
      color: "#1890ff"
    },
    on: {
      successCBK: _vm.imageSuccessCBK
    }
  })], 1) : _vm._e()]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;