"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationType = exports.NotificationContentType = void 0;
var NotificationContentType;
exports.NotificationContentType = NotificationContentType;

(function (NotificationContentType) {
  NotificationContentType["Banner"] = "BANNER";
  NotificationContentType["Book"] = "BOOK";
  NotificationContentType["Normal"] = "NORMAL";
})(NotificationContentType || (exports.NotificationContentType = NotificationContentType = {}));

var NotificationType;
exports.NotificationType = NotificationType;

(function (NotificationType) {
  NotificationType["General"] = "GENERAL";
  NotificationType["Private"] = "PRIVATE";
  NotificationType["Range"] = "RANGE";
  NotificationType["MultiClass"] = "MULTI_CLASS";
})(NotificationType || (exports.NotificationType = NotificationType = {}));