"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authorApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var authorApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/v1/admin/author/',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/v1/admin/author/',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/author/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/v1/admin/author/".concat(id),
      method: 'delete'
    });
  }
};
exports.authorApi = authorApi;