"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("el-dialog", {
    attrs: {
      title: "Cập nhật cấu hình",
      visible: _vm.visible,
      width: "500px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cấu hình",
      prop: "type"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, _vm._l(_vm.ConfigurationTypeTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Giá trị",
      prop: "value"
    }
  }, [_vm.form.dataType == "number" ? _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }) : _vm.form.dataType == "string" ? _c("el-input", {
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }) : [_c("el-radio", {
    attrs: {
      label: "true"
    },
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }, [_vm._v("Có")]), _c("el-radio", {
    attrs: {
      label: "false"
    },
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }, [_vm._v("Không")])]], 2)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;