"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentDefineTypeTrans = exports.ContentDefineType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _ContentDefineTypeTra;

var ContentDefineType;
exports.ContentDefineType = ContentDefineType;

(function (ContentDefineType) {
  ContentDefineType["TermsOfUse"] = "TERMS_OF_USE";
  ContentDefineType["Faq"] = "FAQ";
  ContentDefineType["TutorialUsage"] = "TUTORIAL_USAGE";
  ContentDefineType["ContactInformation"] = "CONTACT_INFORMATION";
})(ContentDefineType || (exports.ContentDefineType = ContentDefineType = {}));

var ContentDefineTypeTrans = (_ContentDefineTypeTra = {}, (0, _defineProperty2.default)(_ContentDefineTypeTra, ContentDefineType.Faq, 'Câu hỏi thường gặp'), (0, _defineProperty2.default)(_ContentDefineTypeTra, ContentDefineType.TermsOfUse, 'Điều khoản & chính sách'), (0, _defineProperty2.default)(_ContentDefineTypeTra, ContentDefineType.TutorialUsage, 'Hướng dẫn cài đặt App'), (0, _defineProperty2.default)(_ContentDefineTypeTra, ContentDefineType.ContactInformation, 'Thông tin liên hệ'), _ContentDefineTypeTra);
exports.ContentDefineTypeTrans = ContentDefineTypeTrans;