"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "chart-container"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8,
      xs: 24,
      offset: 0
    }
  }, [_c("label", {
    staticStyle: {
      display: "inline-block",
      "margin-bottom": "4px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Chọn thời gian")]), _c("br"), _c("el-date-picker", {
    attrs: {
      type: "daterange",
      size: "normal",
      "range-separator": "-",
      "start-placeholder": "",
      format: "dd/MM/yyyy",
      "end-placeholder": ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c("el-col", {
    attrs: {
      span: 6,
      xs: 24,
      offset: 0
    }
  }, [_c("label", {
    staticStyle: {
      display: "inline-block",
      "margin-bottom": "4px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Chọn loại")]), _c("br"), _c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      size: "normal",
      filterable: ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.dateType,
      callback: function callback($$v) {
        _vm.dateType = $$v;
      },
      expression: "dateType"
    }
  }, [_vm._l(_vm.DateTypes, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), _c("el-option", {
    attrs: {
      label: "Theo năm",
      value: "year"
    }
  })], 2)], 1)], 1), _c("BarChart", {
    attrs: {
      data: _vm.data,
      xAxisValue: _vm.xAxisValue,
      "pointer-name": "Lượt truy cập"
    },
    on: {
      "init:ok": _vm.fetchData
    }
  }), _c("label", {
    staticClass: "chart-title",
    attrs: {
      for: ""
    }
  }, [_vm._v(" Biểu đồ lượt truy cập ")])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;