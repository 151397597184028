"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.highlightBookStrategyApi = void 0;

var _request = require("@/utils/request");

var highlightBookStrategyApi = {
  findOne: function findOne(id) {
    return (0, _request.request)({
      url: "/v1/public/highlightBookStrategy/".concat(id)
    });
  }
};
exports.highlightBookStrategyApi = highlightBookStrategyApi;