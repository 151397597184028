"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChooseType = exports.BookChapterSurveyDataType = void 0;
var ChooseType;
exports.ChooseType = ChooseType;

(function (ChooseType) {
  ChooseType["Link"] = "LINK";
  ChooseType["File"] = "FILE";
})(ChooseType || (exports.ChooseType = ChooseType = {}));

var BookChapterSurveyDataType;
exports.BookChapterSurveyDataType = BookChapterSurveyDataType;

(function (BookChapterSurveyDataType) {
  BookChapterSurveyDataType["Url"] = "URL";
  BookChapterSurveyDataType["Html"] = "HTML";
})(BookChapterSurveyDataType || (exports.BookChapterSurveyDataType = BookChapterSurveyDataType = {}));