"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "medium",
      icon: "el-icon-refresh",
      loading: _vm.loading
    },
    on: {
      click: _vm.syncBook
    }
  }, [_vm._v(" Đồng bộ sách giấy từ Opac ")]), _c("div", {
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Đồng bộ lần cuối: ")]), _c("span", [_vm._v(_vm._s(_vm.lastSyncAt ? _vm.$formatDateTime(_vm.lastSyncAt) : "Chưa đồng bộ"))])])], 1);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;