"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecommendBookStrategyStatusTrans = exports.RecommendBookStrategyStatus = exports.HighlightBookStrategyStatusTagColor = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _RecommendBookStrateg, _HighlightBookStrateg;

var RecommendBookStrategyStatus;
exports.RecommendBookStrategyStatus = RecommendBookStrategyStatus;

(function (RecommendBookStrategyStatus) {
  RecommendBookStrategyStatus["Pending"] = "PENDING";
  RecommendBookStrategyStatus["Approve"] = "APPROVE";
  RecommendBookStrategyStatus["Reject"] = "REJECT";
})(RecommendBookStrategyStatus || (exports.RecommendBookStrategyStatus = RecommendBookStrategyStatus = {}));

var RecommendBookStrategyStatusTrans = (_RecommendBookStrateg = {}, (0, _defineProperty2.default)(_RecommendBookStrateg, RecommendBookStrategyStatus.Pending, "Chờ duyệt"), (0, _defineProperty2.default)(_RecommendBookStrateg, RecommendBookStrategyStatus.Approve, "Đã duyệt"), (0, _defineProperty2.default)(_RecommendBookStrateg, RecommendBookStrategyStatus.Reject, "Từ chối"), _RecommendBookStrateg);
exports.RecommendBookStrategyStatusTrans = RecommendBookStrategyStatusTrans;
var HighlightBookStrategyStatusTagColor = (_HighlightBookStrateg = {}, (0, _defineProperty2.default)(_HighlightBookStrateg, RecommendBookStrategyStatus.Pending, ""), (0, _defineProperty2.default)(_HighlightBookStrateg, RecommendBookStrategyStatus.Approve, "success"), (0, _defineProperty2.default)(_HighlightBookStrateg, RecommendBookStrategyStatus.Reject, "danger"), _HighlightBookStrateg);
exports.HighlightBookStrategyStatusTagColor = HighlightBookStrategyStatusTagColor;