"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BookType = exports.BookSyncFromTrans = exports.BookSyncFrom = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _BookSyncFromTrans;

var BookType;
exports.BookType = BookType;

(function (BookType) {
  BookType["Physical"] = "PHYSICAL";
  BookType["EBook"] = "E-BOOK"; //sách điện tử
})(BookType || (exports.BookType = BookType = {}));

var BookSyncFrom;
exports.BookSyncFrom = BookSyncFrom;

(function (BookSyncFrom) {
  BookSyncFrom["Visedu"] = "VISEDU";
  BookSyncFrom["Batch"] = "BATCH";
  BookSyncFrom["Opac"] = "OPAC";
})(BookSyncFrom || (exports.BookSyncFrom = BookSyncFrom = {}));

var BookSyncFromTrans = (_BookSyncFromTrans = {}, (0, _defineProperty2.default)(_BookSyncFromTrans, BookSyncFrom.Visedu, 'Từ thư viện số Dspace'), (0, _defineProperty2.default)(_BookSyncFromTrans, BookSyncFrom.Batch, 'Nhập theo lô'), (0, _defineProperty2.default)(_BookSyncFromTrans, BookSyncFrom.Opac, 'Từ Sierra (Sách giấy)'), _BookSyncFromTrans);
exports.BookSyncFromTrans = BookSyncFromTrans;