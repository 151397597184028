"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DateTypes = void 0;
var DateTypes = [// {
//     label :'Theo năm',
//     value: 'year'
// }, 
{
  label: 'Theo ngày',
  value: 'date'
}, {
  label: 'Theo tuần',
  value: 'week'
}, {
  label: 'Theo tháng',
  value: 'month'
}];
exports.DateTypes = DateTypes;