"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;

require("core-js/modules/es.function.name.js");

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c("div", {
    staticClass: "highlight-book-page"
  }, [_c("div", {
    staticClass: "container highlight-book-container"
  }, [_c("img", {
    staticClass: "img-background",
    attrs: {
      src: _vm.imgBackground
    }
  }), _vm.isFetched ? _c("div", [_c("div", {
    staticClass: "title",
    staticStyle: {
      padding: "0 16px"
    }
  }, [_c("img", {
    attrs: {
      width: "40",
      src: _vm.imgBook,
      alt: ""
    }
  }), _c("label", {
    staticClass: "strategy-name",
    attrs: {
      for: ""
    }
  }, [_vm._v(_vm._s(_vm.data.name))])]), _c("div", {
    staticClass: "content"
  }, [_c("Swiper", {
    attrs: {
      options: _vm.swiperOptionsObject
    }
  }, _vm._l(_vm.bookChunks, function (item, index) {
    return _c("SwiperSlide", {
      key: index
    }, [_c("div", {
      staticClass: "book-item-container"
    }, _vm._l(item, function (book, index) {
      return _c("div", {
        key: index
      }, [_c("BookItem", {
        attrs: {
          book: book
        }
      })], 1);
    }), 0)]);
  }), 1)], 1)]) : _vm._e()])]);
};

exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;