import { render, staticRenderFns } from "./ContentDefineModal.vue?vue&type=template&id=8ace7a68&"
import script from "./ContentDefineModal.vue?vue&type=script&lang=ts&"
export * from "./ContentDefineModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chuongdev/Desktop/project-bangiao/214-admin/smartlib-portal-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8ace7a68')) {
      api.createRecord('8ace7a68', component.options)
    } else {
      api.reload('8ace7a68', component.options)
    }
    module.hot.accept("./ContentDefineModal.vue?vue&type=template&id=8ace7a68&", function () {
      api.rerender('8ace7a68', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/content-define/components/ContentDefineModal.vue"
export default component.exports